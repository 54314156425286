@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in {
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
}
.fade-in.delay-1 {
  animation-delay: 0.1s;
}
.fade-in.delay-2 {
  animation-delay: 0.2s;
}
.fade-in.delay-3 {
  animation-delay: 0.3s;
}
.fade-in.delay-4 {
  animation-delay: 0.4s;
}
.fade-in.delay-5 {
  animation-delay: 0.5s;
}
.fade-in.delay-6 {
  animation-delay: 0.6s;
}
.fade-in.delay-7 {
  animation-delay: 0.7s;
}
.fade-in.delay-8 {
  animation-delay: 0.8s;
}
.fade-in.delay-9 {
  animation-delay: 0.9s;
}
.fade-in.delay-10 {
  animation-delay: 1s;
}
.fade-in.delay-11 {
  animation-delay: 1.1s;
}
.fade-in.delay-12 {
  animation-delay: 1.2s;
}
.fade-in.delay-13 {
  animation-delay: 1.2s;
}
.fade-in.delay-14 {
  animation-delay: 1.2s;
}
.fade-in.delay-15 {
  animation-delay: 1.2s;
}
